import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import useAuth from "../helpers/authentication.js";

const SignInButton = () => {
  const {t} = useTranslation("common");

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const routeChange = (path) =>{ 
    navigate(path);
  }

  return (
    !isAuthenticated && (
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => routeChange('/login')}
      >
        {t('signInButton')}
      </Button>
    )
  )
};

export default SignInButton;