import { useState, useEffect, useCallback } from 'react';

export default function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const runEffect = useCallback(async () => {
    let loadedAccessToken = localStorage.getItem('access_token');
    
    if (loadedAccessToken) {
      if (await checkAccessToken(loadedAccessToken)) {
        setAccessToken(loadedAccessToken);
        setIsAuthenticated(true);  
      } else {
        if (!await refreshAccessToken()) {
          setAccessToken(null);
          setIsAuthenticated(false);
        }
      }
    } else {
      if (!await refreshAccessToken()) {
        setAccessToken(null);
        setIsAuthenticated(false);
      }
    }
  }, [])

  useEffect(() => {
    runEffect();
  }, [runEffect]);


  const getAccessTokenSilently = async () => {
    if (((new Date()) - lastRefresh) > (15 * 60 * 1000)) {
      await checkAccessToken(accessToken);
    }
    return accessToken;
  }


  const checkAccessToken = async (token) => {
    let response = await fetch('/check-access-token', {
      method: "GET",
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}, 
    })

    if (response.status === 401) return false;
    return true;
  }

  const refreshAccessToken = async () => {
    let refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      let response = await fetch('/refresh-access-token', {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${refreshToken}`}, 
      })
      if (response.status !== 401) {
        let data = await response.json();
        localStorage.setItem("access_token", data.access_token);
        setAccessToken(data.access_token);
        setIsAuthenticated(true);
        setLastRefresh(new Date());
        return true;
      } 
      else {
        setAccessToken(null);
        setIsAuthenticated(false);
        return false;
      }
    } else {
      setAccessToken(null);
      setIsAuthenticated(false);
      return false;
    }
  }

  const login = async (email, password) => {
    let response = await fetch('/login', {
      method: "POST",
      headers: {'Content-Type': 'application/json'}, 
      body: JSON.stringify({
        "email": email,
        "password": password,
      })
    })
    if (response.status === 200) {
      let data = await response.json();
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      setAccessToken(data.access_token);
      setIsAuthenticated(true);
      setLastRefresh(new Date());
      return {
        succeeded: true
      };
    } 
    else {
      setAccessToken(null);
      setIsAuthenticated(false);
      return {
        succeeded: false,
        error: (await response.text()),
      };
    }
  }

  const logout = (params) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setAccessToken(null);
    setIsAuthenticated(false);
    if (params.returnTo) {
      window.location.replace(params.returnTo);
    }
  }

  const register = async (name, email, password) => {
    let response = await fetch('/register', {
      method: "POST",
      headers: {'Content-Type': 'application/json'}, 
      body: JSON.stringify({
        "name": name,
        "email": email,
        "password": password,
      })
    })
    if (response.status === 200) {
      return {
        succeeded: true
      };
    } 
    else {
      if (response.status === 500) {
        return {
          succeeded: false,
          error: "Failed to register user.",
        };
      }
      return {
        succeeded: false,
        error: (await response.text()),
      };
    }
  }

  return {isAuthenticated, getAccessTokenSilently, login, logout, register};
}