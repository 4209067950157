import * as React from 'react';
import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import useAuth from "./helpers/authentication.js";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, CircleMarker, Circle, Popup } from "react-leaflet";
import style from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea'
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grow from '@mui/material/Grow';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

import ContactSignUpDialog from './components/ContactSignUpDialog'; 
import ReadMoreText from './components/ReadMoreText';
import SwipeableCarousel from './components/SwipeableCarousel';

import BackgroundImage1 from "./static/copenhagen-skyline-in-the-evening-denmark.jpg"
import DemoApartmentImage1 from './static/Oestre-Havnepark-19-3.-3-3-500x375.jpg'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://residentnow.com/">
        Resident Now ApS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function SearchLocationSizeCard({ active, onExited, onNext, onPrev, searchPriorities, setSearchPriorities, searchCity, setSearchCity, searchSize, setSearchSize, searchRooms, setSearchRooms }) {
  const {t} = useTranslation("common");

  const handleCitySelectChange = (event) => {
    setSearchCity(event.target.value);
  };

  const handleSizeSliderChange = (event, newValue) => {
    setSearchSize(newValue);
  };

  const handleRoomsSliderChange = (event, newValue) => {
    setSearchRooms(newValue);
  };

  const handlePrioritiesChange = (key, event) => {
    let objClone = { ...searchPriorities };
    objClone[key] = event.target.checked
    setSearchPriorities(objClone);
  };

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
          minHeight: 400,
          minWidth: 300,
          maxWidth: 360,
          marginTop: { xs: 16, sm: 4 },
          marginBottom: 16,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
        }}
      >
        <CardHeader title={t("homesearch.locationSize.title")} sx={{ pb: 0 }} />
        <CardContent>
          <Container>
            <Box
              sx={{
                minHeight: 288,
                minWidth: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'space-between',
              }}
            >
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      {t("homesearch.locationSize.location.title")}
                    </Typography>
                    <hr/>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="search-city-select-label">{t("homesearch.locationSize.city.label")}</InputLabel>
                      <Select
                        labelId="search-city-select-label"
                        id="search-city-select"
                        value={searchCity}
                        label={t("homesearch.locationSize.city.label")}
                        onChange={handleCitySelectChange}
                      >
                        <MenuItem value={"Aarhus"}>Aarhus</MenuItem>
                        <MenuItem value={"Aalborg"}>Aalborg</MenuItem>
                        <MenuItem value={"København"}>København</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      {t("homesearch.locationSize.size.title")}
                    </Typography>
                    <hr/>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.locationSize.size.label")}
                    </Typography>
                    <Slider
                      value={searchSize}
                      min={1}
                      max={1000}
                      step={1}
                      onChange={handleSizeSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'start',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-size-priority-checkbox" checked={searchPriorities['searchSize']} onChange={(e) => {handlePrioritiesChange('searchSize', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.locationSize.rooms.label")}
                    </Typography>
                    <Slider
                      value={searchRooms}
                      min={1}
                      max={9}
                      step={1}
                      onChange={handleRoomsSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'start',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-rooms-priority-checkbox" checked={searchPriorities['searchRooms']} onChange={(e) => {handlePrioritiesChange('searchRooms', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                </Grid>
              </Box>
                {/* <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="search-city"
                    label="City"
                    type="text"
                    id="search-city"
                    value={searchCity}
                    onChange={(e) => setSearchCity(e.target.value)}
                  />
                </Grid> */}
                {/* <Grid item xs={8}>
                  <Typography variant="subtitle1">
                    {t("homesearch.location.centerdist.label")}
                  </Typography>
                  <Slider
                    value={searchCenterDist}
                    min={1}
                    max={200}
                    step={1}
                    onChange={handleCenterDistSliderChange}
                    valueLabelDisplay="auto"
                    sx={{ minWidth: 150 }}
                  />
                </Grid>
                <Grid item xs={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <FormControlLabel control={<Checkbox id="search-center-dist-priority-checkbox" />} label={t("homesearch.priority")} />
                </Grid> */}
              <Box 
                sx={{ 
                  mt: 'auto',
                  pt: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <IconButton onClick={onPrev} sx={{
                      
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary" align="center">
                    {t('homesearch.previous')}
                  </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <IconButton onClick={onNext} sx={{
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary" align="center">
                    {t('homesearch.next')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Grow>
  );
}

function SearchPriceCard({ active, onExited, onNext, onPrev, searchPriorities, setSearchPriorities, searchMonthlyRent, setSearchMonthlyRent, searchMonthlyAccount, setSearchMonthlyAccount, searchDeposit, setSearchDeposit }) {
  const {t} = useTranslation("common");
  
  const handleMonthlyRentSliderChange = (event, newValue) => {
    setSearchMonthlyRent(newValue);
  };

  const handleMonthlyAccountSliderChange = (event, newValue) => {
    setSearchMonthlyAccount(newValue);
  };

  const handleDepositSliderChange = (event, newValue) => {
    setSearchDeposit(newValue);
  };

  const handlePrioritiesChange = (key, event) => {
    let objClone = { ...searchPriorities };
    objClone[key] = event.target.checked
    setSearchPriorities(objClone);
  };

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
          minHeight: 400,
          minWidth: 300,
          maxWidth: 360,
          marginTop: { xs: 16, sm: 4 },
          marginBottom: 16,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
        }}
      >
        <CardHeader title={t("homesearch.price.title")} sx={{ pb: 0 }} />
        <CardContent>
          <hr/>
          <Container maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.price.rent.label")}
                    </Typography>
                    <Slider
                      id="search-monthly-rent-slider"
                      value={searchMonthlyRent}
                      min={0}
                      max={50000}
                      step={100}
                      onChange={handleMonthlyRentSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'start',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-monthly-rent-priority-checkbox" checked={searchPriorities['searchMonthlyRent']} onChange={(e) => {handlePrioritiesChange('searchMonthlyRent', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.price.account.label")}
                    </Typography>
                    <Slider
                      id="search-monthly-account-slider"
                      value={searchMonthlyAccount}
                      min={0}
                      max={20000}
                      step={100}
                      onChange={handleMonthlyAccountSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'start',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-monthly-account-priority-checkbox" checked={searchPriorities['searchMonthlyAccount']} onChange={(e) => {handlePrioritiesChange('searchMonthlyAccount', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.price.deposit.label")}
                    </Typography>
                    <Slider
                      id="search-deposit-slider"
                      value={searchDeposit}
                      min={0}
                      max={250000}
                      step={100}
                      onChange={handleDepositSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'start',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-deposit-priority-checkbox" checked={searchPriorities['searchDeposit']} onChange={(e) => {handlePrioritiesChange('searchDeposit', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                </Grid>
                <Box 
                  sx={{ 
                    mt: 'auto',
                    pt: 8,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={onPrev} sx={{
                        
                      }}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t('homesearch.previous')}
                    </Typography>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={onNext} sx={{
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t('homesearch.next')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Grow>
  );
}

function SearchSizeCard({ active, onExited, onNext, onPrev, searchPriorities, setSearchPriorities, searchSize, setSearchSize, searchRooms, setSearchRooms }) {
  const {t} = useTranslation("common");

  const handleSizeSliderChange = (event, newValue) => {
    setSearchSize(newValue);
  };

  const handleRoomsSliderChange = (event, newValue) => {
    setSearchRooms(newValue);
  };

  const handlePrioritiesChange = (key, event) => {
    let objClone = { ...searchPriorities };
    objClone[key] = event.target.checked
    setSearchPriorities(objClone);
  };

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
          minHeight: 400,
          minWidth: 300,
          maxWidth: 360,
          marginTop: { xs: 16, sm: 4 },
          marginBottom: 16,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
        }}
      >
        <CardHeader title={t("homesearch.size.title")} sx={{ pb: 0 }} />
        <CardContent>
          <hr/>
          <Container maxWidth="xs">
            <Box
              sx={{
                minHeight: 312,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'space-between',
              }}
            >
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.size.size.label")}
                    </Typography>
                    <Slider
                      value={searchSize}
                      min={1}
                      max={1000}
                      step={1}
                      onChange={handleSizeSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-size-priority-checkbox" checked={searchPriorities['searchSize']} onChange={(e) => {handlePrioritiesChange('searchSize', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">
                      {t("homesearch.size.rooms.label")}
                    </Typography>
                    <Slider
                      value={searchRooms}
                      min={1}
                      max={9}
                      step={1}
                      onChange={handleRoomsSliderChange}
                      valueLabelDisplay="auto"
                      sx={{ minWidth: 150 }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <FormControlLabel control={<Checkbox id="search-rooms-priority-checkbox" checked={searchPriorities['searchRooms']} onChange={(e) => {handlePrioritiesChange('searchRooms', e)}} />} label={t("homesearch.priority")} />
                  </Grid>
                </Grid>
              </Box>
              <Box 
                sx={{ 
                  mt: 'auto',
                  pt: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <IconButton onClick={onPrev} sx={{
                      
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary" align="center">
                    {t('homesearch.previous')}
                  </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <IconButton onClick={onNext} sx={{
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.secondary" align="center">
                    {t('homesearch.next')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Grow>
  );
}

function SearchLifestyleInventoryCard({ active, onExited, onNext, onPrev, searchPriorities, setSearchPriorities, searchLifestyle, setSearchLifestyle, searchInventory, setSearchInventory }) {
  const {t} = useTranslation("common");

  var lifestyleDisplayLabels = [t("homesearch.lifestyle.pets"), t("homesearch.lifestyle.shareable"), t("homesearch.lifestyle.smoking"), t("homesearch.lifestyle.ageFriendly")];
  var lifestyleCheckboxInitObj = {};

  var inventoryDisplayLabels = [t("homesearch.inventory.furnished"), t("homesearch.inventory.washingmachine"), t("homesearch.inventory.dryermachine"), t("homesearch.inventory.dishwasher")];
  var inventoryCheckboxInitObj = {};

  Object.entries(searchLifestyle).forEach(([key, value], index) => {
    lifestyleCheckboxInitObj[key] = {'value': value, 'label': t(lifestyleDisplayLabels[index])};
  });

  Object.entries(searchInventory).forEach(([key, value], index) => {
    inventoryCheckboxInitObj[key] = {'value': value, 'label': t(inventoryDisplayLabels[index])};
  });

  const handleLifestyleSearchChange = (key, event) => {
    let objClone = { ...searchLifestyle };
    objClone[key] = event.target.checked
    setSearchLifestyle(objClone);
  };

  const handleInventorySearchChange = (key, event) => {
    let objClone = { ...searchInventory };
    objClone[key] = event.target.checked
    setSearchInventory(objClone);
  };

  const handleSearchPrioritiesChange = (key, event) => {
    let objClone = { ...searchPriorities };
    objClone[key] = event.target.checked
    setSearchPriorities(objClone);
  };

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
          minHeight: 400,
          minWidth: 300,
          maxWidth: 360,
          marginTop: { xs: 16, sm: 4 },
          marginBottom: 16,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
        }}
      >
        <CardHeader title={t("homesearch.lifestyleInventory.title")} sx={{ pb: 0 }} />
        <CardContent>
          <Container maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {t("homesearch.lifestyleInventory.lifestyle.title")}
                  </Typography>
                  <hr/>
                </Grid>
                {Object.entries(lifestyleCheckboxInitObj).map(([key, value]) => (
                  <Grid container key={key} spacing={2}>
                    <Grid item xs={8}>
                      <FormControlLabel control={<Checkbox checked={value['value']} onChange={(e) => {handleLifestyleSearchChange(key, e)}} id={`search-${key}-checkbox`} />} label={value['label']} />
                    </Grid>
                    <Grid item xs={4} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <FormControlLabel control={<Checkbox checked={searchPriorities[key]} onChange={(e) => {handleSearchPrioritiesChange(key, e)}} id={`search-${key}-priority-checkbox`} />} label={t("homesearch.priority")} />
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {t("homesearch.lifestyleInventory.inventory.title")}
                  </Typography>
                  <hr/>
                </Grid>
                {Object.entries(inventoryCheckboxInitObj).map(([key, value]) => (
                  <Grid container key={key} spacing={2}>
                    <Grid item xs={8}>
                      <FormControlLabel control={<Checkbox checked={value['value']} onChange={(e) => {handleInventorySearchChange(key, e)}} id={`search-${key}-checkbox`} />} label={value['label']} />
                    </Grid>
                    <Grid item xs={4} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <FormControlLabel control={<Checkbox checked={searchPriorities[key]} onChange={(e) => {handleSearchPrioritiesChange(key, e)}} id={`search-${key}-priority-checkbox`} />} label={t("homesearch.priority")} />
                    </Grid>
                  </Grid>
                ))}
                <Box 
                  sx={{ 
                    mt: 'auto',
                    pt: 8,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={onPrev} sx={{
                        
                      }}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t('homesearch.previous')}
                    </Typography>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={onNext} sx={{
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t('homesearch.next')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Grow>
  );
}

function SearchFacilitiesCard({ active, onExited, onNext, onPrev, searchPriorities, setSearchPriorities, searchFacilities, setSearchFacilities }) {
  const {t} = useTranslation("common");

  var displayLabels = [t("homesearch.facilities.balcony"), t("homesearch.facilities.garden"), t("homesearch.facilities.parking"), t("homesearch.facilities.chargingStation"), t("homesearch.facilities.elevator"), t("homesearch.facilities.storage")];
  var checkboxInitObj = {};

  Object.entries(searchFacilities).forEach(([key, value], index) => {
    checkboxInitObj[key] = {'value': value, 'label': t(displayLabels[index])};
  });

  const handleSearchChange = (key, event) => {
    let objClone = { ...searchFacilities };
    objClone[key] = event.target.checked
    setSearchFacilities(objClone);
  };

  const handleSearchPrioritiesChange = (key, event) => {
    let objClone = { ...searchPriorities };
    objClone[key] = event.target.checked
    setSearchPriorities(objClone);
  };

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
          minHeight: 400,
          minWidth: 300,
          maxWidth: 360,
          marginTop: { xs: 16, sm: 4 },
          marginBottom: 16,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
        }}
      >
        <CardHeader title={t("homesearch.facilities.title")} sx={{ pb: 0 }} />
        <CardContent>
          <hr/>
          <Container maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mt: 1 }}>
                {Object.entries(checkboxInitObj).map(([key, value]) => (
                  <Grid container key={key} spacing={2}>
                    <Grid item xs={8}>
                      <FormControlLabel control={<Checkbox checked={value['value']} onChange={(e) => {handleSearchChange(key, e)}} id={`search-${key}-checkbox`} />} label={value['label']} />
                    </Grid>
                    <Grid item xs={4} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <FormControlLabel control={<Checkbox checked={searchPriorities[key]} onChange={(e) => {handleSearchPrioritiesChange(key, e)}} id={`search-${key}-priority-checkbox`} />} label={t("homesearch.priority")} />
                    </Grid>
                  </Grid>
                ))}
                <Box 
                  sx={{ 
                    mt: 'auto',
                    pt: 8,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={onPrev} sx={{
                        
                      }}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t('homesearch.previous')}
                    </Typography>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={onNext} sx={{
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t('homesearch.next')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Grow>
  );
}

function SearchStyleCard({ active, onExited, onNext }) {
  const {t} = useTranslation("common");

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
              minWidth: 300,
              maxWidth: 360,
              marginTop: 16,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
            }}>
        <CardHeader title="Choose a city"/>
        <CardContent>
          <Container maxWidth="xs">
            <Box
              sx={{
                marginTop: { xs: 16, sm: 4 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onNext}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("homesearch.next")}
                </Button>
              </Box>
            </Box>
          </Container>
        </CardContent>
      </Card>
    </Grow>
  );
}

function CallListSignUpCard({ setSuccessAlert, setErrorAlert, setOpenContactSignUpDialog }) {
  const {t} = useTranslation("common");

  const { isAuthenticated, getAccessTokenSilently } = useAuth();

  const [phoneCountryCode, setPhoneCountryCode] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const countryCodes = [
    "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", "+40", "+41", "+43", "+44",
    "+45", "+46", "+47", "+48", "+49", "+51", "+52", "+53", "+54", "+55", "+56", "+57", "+58", "+60", "+61",
    "+62", "+63", "+64", "+65", "+66", "+81", "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95",
    "+98", "+211", "+212", "+213", "+216", "+218", "+220", "+221", "+222", "+223", "+224", "+225", "+226",
    "+227", "+228", "+229", "+230", "+231", "+232", "+233", "+234", "+235", "+236", "+237", "+238", "+239",
    "+240", "+241", "+242", "+243", "+244", "+245", "+246", "+247", "+248", "+249", "+250", "+251", "+252",
    "+253", "+254", "+255", "+256", "+257", "+258", "+260", "+261", "+262", "+263", "+264", "+265", "+266",
    "+267", "+268", "+269", "+290", "+291", "+297", "+298", "+299", "+350", "+351", "+352", "+353", "+354",
    "+355", "+356", "+357", "+358", "+359", "+370", "+371", "+372", "+373", "+374", "+375", "+376", "+377",
    "+378", "+379", "+380", "+381", "+382", "+383", "+385", "+386", "+387", "+389", "+420", "+421", "+423",
    "+500", "+501", "+502", "+503", "+504", "+505", "+506", "+507", "+508", "+509", "+590", "+591", "+592",
    "+593", "+594", "+595", "+596", "+597", "+598", "+599", "+670", "+672", "+673", "+674", "+675", "+676",
    "+677", "+678", "+679", "+680", "+681", "+682", "+683", "+685", "+686", "+687", "+688", "+689", "+690",
    "+691", "+692", "+850", "+852", "+853", "+855", "+856", "+880", "+886", "+960", "+961", "+962", "+963",
    "+964", "+965", "+966", "+967", "+968", "+970", "+971", "+972", "+973", "+974", "+975", "+976", "+977",
    "+992", "+993", "+994", "+995", "+996", "+998", "+1242", "+1246", "+1264", "+1268", "+1284", "+1340",
    "+1345", "+1441", "+1473", "+1649", "+1664", "+1670", "+1671", "+1684", "+1721", "+1758", "+1767",
    "+1784", "+1849", "+1868", "+1869", "+1876", "+1939"
  ];

  const callListSignUp = async (event) => {
    if (isAuthenticated) {
      await fetch('/add-user-to-call-list', {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
        body: JSON.stringify({
          "phone_country_code": phoneCountryCode,
          "phone": phoneNumber,
        })
      }).then((res) =>
        res.json().then(() => {
          setSuccessAlert(t('callListSignUp.successAlert'));
        }).catch(() => {
          setErrorAlert(t('callListSignUp.errorAlert'));
        })
      );
    } else {
      setOpenContactSignUpDialog(true);
    }
  };

  return (
    <Card sx={{
        minWidth: {xs: 300, md: 200}, 
        maxWidth: {xs: 360, md: 260},
        marginTop: { xs: 2, sm: 4 },
        mx: {xs: 4, md: 0},
        position: {xs: 'static', sm: '-webkit-sticky'}, /* Safari */
        position: {xs: 'static', sm: 'sticky'},
        top: {xs: 0, sm: 160},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <CardContent>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t('callListSignUp.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <Box>
                  <Select
                    id="phone-country-code-select"
                    value={phoneCountryCode}
                    onChange={e => setPhoneCountryCode(e.target.value)}
                    autoWidth
                  >
                    {countryCodes.map((countryCode) => (
                      <MenuItem value={countryCode}>{countryCode}</MenuItem>
                    ))}
                  </Select>
                  <TextField
                    required
                    name="phone-number"
                    label={t('callListSignUp.phoneNumber.label')}
                    type="tel"
                    id="phone-number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    autoComplete="phone-number"
                    sx={{
                      maxWidth: 182
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            fullWidth
            type='submit'
            variant="contained"
            onClick={callListSignUp}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('callListSignUp.button.label')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

function EmailListSignUpCard({ setSuccessAlert, setErrorAlert, setOpenContactSignUpDialog }) {
  const {t} = useTranslation("common");

  const { isAuthenticated, getAccessTokenSilently } = useAuth();

  const emailListSignUp = async (event) => {
    if (isAuthenticated) {
      await fetch('/add-user-to-email-list', {
        headers: {'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
      }).then((res) =>
        res.json().then(() => {
          setSuccessAlert(t('emailListSignUp.successAlert'));
        }).catch(() => {
          setErrorAlert(t('emailListSignUp.errorAlert'));
        })
      );
    } else {
      setOpenContactSignUpDialog(true);
    }
  };

  return (
    <Card sx={{
        minWidth: {xs: 300, md: 200}, 
        maxWidth: {xs: 360, md: 260},
        marginTop: 2,
        marginBottom: {xs: 2, md: 0},
        mx: {xs: 4, md: 0},
        position: {xs: 'static', sm: '-webkit-sticky'}, /* Safari */
        position: {xs: 'static', sm: 'sticky'},
        top: {xs: 0, sm: 444},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <CardContent>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">
            {t('emailListSignUp.title')}
          </Typography>
          <Button
            fullWidth
            type='submit'
            variant='contained'
            onClick={emailListSignUp}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('emailListSignUp.label')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

function HomeListingCard({ data, changeSelectedListing }) {
  const {t} = useTranslation("common");

  return (
    <Card sx={{ minWidth: 250, maxWidth: 250 }}>
      <CardActionArea onClick={() => {changeSelectedListing(data)}}>
        <CardMedia
          sx={{ height: 140 }}
          image={data['images'][0]}
          title={t("homesearch.homeListingCard.title")}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {t('homesearch.homeListingCard.info', {'size': data.size, 'rooms': data.rooms})} {data.type === "apartment" ? t('homesearch.listingType.apartment') : t('homesearch.listingType.house')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {data.rental_period_type === "unlimited" ? t("homesearch.unlimited"): data.move_out_date}
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div">
            {data.monthly_rent} {data.currency}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function SearchResultCard({ active, onExited, results, changeSelectedListing, onPrev, setSuccessAlert, setErrorAlert }) {
  const {t} = useTranslation("common");

  const [openContactSignUpDialog, setOpenContactSignUpDialog] = React.useState(false);

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Card sx={{ 
                minWidth: { xs: 300, md: 460 },
                maxWidth: { xs: 360, md: 592 },
                marginTop: { xs: 16, sm: 4 },
                marginBottom: { xs: 0, md: 16 },
                mx: {xs: 4, md: 0},
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
              }}
            >
              <IconButton onClick={onPrev} sx={{
                  marginLeft: {xs: 2, md: 3},
                  marginRight: 'auto',
                  marginTop: 1.5,
                  alignItems: 'center',
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <CardHeader title={t("homesearch.searchResult.title")} sx={{
                  position: 'absolute',
                }}
              />
              <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    minWidth: { xs: 300, md: 300 },
                    maxWidth: { xs: 360, md: 516 },
                    mx: { xs: 0, md: 2 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Grid container spacing={2} sx={{ 
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {results.length > 0 ? results.map((result, index) => (
                      <Grid item size={{ xs: 12, md: 6 }} sx={{ alignSelf: 'center' }}>
                        <HomeListingCard key={index} data={result} changeSelectedListing={changeSelectedListing} /> 
                      </Grid>
                    )) : <Typography variant='h6'>{t("homesearch.searchResult.noResults")}</Typography>}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <CallListSignUpCard setSuccessAlert={setSuccessAlert} setErrorAlert={setErrorAlert} setOpenContactSignUpDialog={setOpenContactSignUpDialog} />
            <EmailListSignUpCard setSuccessAlert={setSuccessAlert} setErrorAlert={setErrorAlert} setOpenContactSignUpDialog={setOpenContactSignUpDialog} />
          </Grid>
        </Grid>
          <ContactSignUpDialog openContact={openContactSignUpDialog} handleCloseContact={() => setOpenContactSignUpDialog(false)} />
      </Box>
    </Grow>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ViewListingDetailsTable({ data }) {
  const {t} = useTranslation("common");

  var homeDetailsTableArr = [
    {'label': t("homesearch.viewListing.detailsTable.type"), 'value': data.type === "apartment" ? t('homesearch.listingType.apartment') : t('homesearch.listingType.house')},
    {'label': t("homesearch.viewListing.detailsTable.size"), 'value': data.size + " ㎡"},
    {'label': t("homesearch.viewListing.detailsTable.rooms"), 'value': data.rooms},
    {'label': t("homesearch.viewListing.detailsTable.washingMachine"), 'value': data.washing_machine ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.dryer"), 'value': data.dryer_machine ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.dishwasher"), 'value': data.dishwasher ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.balcony"), 'value': data.balcony ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.garden"), 'value': data.garden ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.pets"), 'value': data.pets ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.parking"), 'value': data.parking ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.chargingStation"), 'value': data.charging_station ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.elevator"), 'value': data.elevator ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.storage"), 'value': data.storage ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.shareable"), 'value': data.shareable ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.smoking"), 'value': data.smoking ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.furnished"), 'value': data.furnished ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.ageFriendly"), 'value': data.age_friendly ? t("homesearch.viewListing.detailsTable.yes") : t("homesearch.viewListing.detailsTable.no")},
    {'label': t("homesearch.viewListing.detailsTable.energyLabel"), 'value': data.energy_label}
  ];

  var rentDetailsTableArr = [
    {'label': t("homesearch.viewListing.detailsTable.monthlyRent"), 'value': `${data.monthly_rent}${data.currency}`},
    {'label': t("homesearch.viewListing.detailsTable.monthlyHeatingAccount"), 'value': `${data.monthly_water_account}${data.currency}`},
    {'label': t("homesearch.viewListing.detailsTable.monthlyWaterAccount"), 'value': `${data.monthly_heating_account}${data.currency}`},
    {'label': t("homesearch.viewListing.detailsTable.rentalPeriod"), 'value': data.rental_period_type},
    {'label': t("homesearch.viewListing.detailsTable.moveInDate"), 'value': data.move_in_date},
    {'label': t("homesearch.viewListing.detailsTable.deposit"), 'value': `${data.deposit}${data.currency}`},
    {'label': t("homesearch.viewListing.detailsTable.prepaidRent"), 'value': `${data.prepaid_rent}${data.currency}`}
  ];

  return (
    <TableContainer>
      <Grid container spacing={2}>
        <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
          <Grid item xs={6} sx={{ minWidth: 260 }}>
            <Table sx={{ minWidth: 260 }} size="small">
              <TableBody>
                {homeDetailsTableArr.slice(0, 9).map((row) => (
                  <StyledTableRow
                    key={row.label}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6} sx={{ minWidth: 260 }}>
            <Table sx={{ minWidth: 260 }} size="small">
              <TableBody>
                {homeDetailsTableArr.slice(9).map((row) => (
                  <StyledTableRow
                  key={row.label}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
          <Grid item xs={6} sx={{ minWidth: 260 }}>
            <Table sx={{ minWidth: 260 }} size="small">
              <TableBody>
                {rentDetailsTableArr.slice(0, 4).map((row) => (
                  <StyledTableRow
                    key={row.label}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6} sx={{ minWidth: 260 }}>
            <Table sx={{ minWidth: 260 }} size="small">
              <TableBody>
                {rentDetailsTableArr.slice(4).map((row) => (
                  <StyledTableRow
                    key={row.label}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

function ViewListingCard({ active, routeChange, data, onExited, onPrev, userData }) {
  const {t} = useTranslation("common");
  
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const [membership, setMembership] = React.useState(null);

  const [listingImages, setListingImages] = React.useState([]);
  const [center, setCenter] = React.useState([57.045663, 9.927370]);

  const [openContact, setOpenContact] = React.useState(false);
  const handleOpenContact = () => setOpenContact(true);
  const handleCloseContact = () => setOpenContact(false);

  const [contactInfo, setContactInfo] = React.useState(false);

  const checkMembershipExpiration = (dateStr) => {
    var membershipExpiration = new Date(dateStr);
    const today = new Date();
    setMembership(membershipExpiration >= today);
  }

  const getContactInfo = useCallback(async () => {
    if (userData) {
      const accessToken = await getAccessTokenSilently();
  
      await fetch(`/get-organisation-contact?org_id=${
        JSON.stringify(data.organisation)}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then((res) =>
        res.json().then((data) => {
          setContactInfo(data);
        })
      );
    }
  }, [userData, getAccessTokenSilently, data]);

  if (active && !contactInfo) {
    getContactInfo();
  }

  if (isAuthenticated && userData && membership === null) {
    checkMembershipExpiration(userData.membership_expiration);
  }

  if (data.images.length > 0 && data.floor_plan && listingImages[0] !== data.images && listingImages[listingImages.length -1] !== data.floor_plan) {
    setListingImages(data.images.concat([data.floor_plan]));
  }

  if (data.geo_location && (center[0] !== data.geo_location[0] || center[1] !== data.geo_location[1])) {
    setCenter(data.geo_location);
  }

  return (
    <Grow direction="up" in={active} onExited={onExited} mountOnEnter unmountOnExit>
      <Card sx={{ 
          minWidth: { xs: 300, md: 660 },
          maxWidth: { xs: 360, md: 860 },
          marginTop: { xs: 16, sm: 4 },
          marginBottom: 16,
          mx: {xs: 4, md: 0},
        }}
      >
        <IconButton onClick={onPrev} sx={{
            marginLeft: 2,
            marginTop: 2,
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <CardContent sx={{
            mx: 4,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SwipeableCarousel images={listingImages} />
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{minWidth: 300}}
            >
              <Typography variant='h4' sx={{minWidth: 300}}>
                {t('homesearch.viewListing.mainCard.sizeInfo', {'size': data.size, 'rooms': data.rooms})} {data.type === "apartment" ? t('homesearch.listingType.apartment') : t('homesearch.listingType.house')}
              </Typography>
              <ReadMoreText text={data.description} lessLength={300} />
            </Grid>
            <Grid item xs={6} sx={{ 
                minWidth: 300,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center', 
              }}
            >
              <Card sx={{ 
                  minWidth: 300,
                  maxWidth: 360,
                  marginLeft: { xs: 0, md: 4 },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center', 
                }}
              >
                <CardContent sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        {t('homesearch.viewListing.mainCard.monthlyRent')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {data.monthly_rent} {data.currency}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {t('homesearch.viewListing.mainCard.monthlyAccount')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{
                          float: 'right',
                          fontWeight: 'bold',
                        }}
                      >
                        {data.monthly_heating_account + data.monthly_water_account} {data.currency}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {t('homesearch.viewListing.mainCard.moveInPrice')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{
                          float: 'right',
                          fontWeight: 'bold',
                        }}
                      >
                        {data.deposit + data.prepaid_rent} {data.currency}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {t('homesearch.viewListing.mainCard.moveInDate')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{
                          float: 'right',
                          fontWeight: 'bold',
                        }}
                      >
                        {data.move_in_date}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {t('homesearch.viewListing.mainCard.rentalPeriod')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{
                          float: 'right',
                          fontWeight: 'bold',
                        }}
                      >
                        {data.rental_period_type === "unlimited" ? t("homesearch.unlimited"): data.move_out_date}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button onClick={handleOpenContact} fullWidth variant="contained" sx={{ marginTop: 4 }}>
                    {t('homesearch.viewListing.mainCard.contact')}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ViewListingDetailsTable data={data} />
            </Grid>
            <Grid item xs={12}
            >
              <MapContainer center={center} zoom={13} scrollWheelZoom={true} style={{ height: "400px" }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Circle 
                  center={{lat:center[0], lng: center[1]}}
                  color="gray"
                  fillColor="#262626" 
                  radius={250}
                />
              </MapContainer>
            </Grid>
          </Grid>
        </CardContent>
        {membership ? 
          <Dialog
            open={openContact}
            onClose={handleCloseContact}
          >
            <DialogTitle>
              {t('homesearch.viewListing.contactDialog.title')}
              <hr />
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseContact}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Typography variant="subtitle1">
                {t('homesearch.viewListing.contactDialog.email', {'email': contactInfo.contact_email})}
              </Typography>
              <Typography variant="subtitle1">
                {t('homesearch.viewListing.contactDialog.phone', {'phone': contactInfo.contact_phone})}
              </Typography>
              <hr />
              <Typography variant='caption'>
                {t('homesearch.viewListing.hereToHelp')}
              </Typography>
              <br />
              <Typography variant='caption'>
                {t('homesearch.viewListing.sendUsAnEmail')}&nbsp;
                  <Link href="mailto:support@residentnow.com" color="primary">
                    support@residentnow.com
                  </Link>
                  .
              </Typography>
            </DialogContent>
          </Dialog>
          : 
          membership === null ? 
            <Dialog
              open={openContact}
              onClose={handleCloseContact}
            >
              <DialogTitle sx={{ maxWidth: 300 }}>
                {t('homesearch.viewListing.SignUpDialog.title')}
                <hr />
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseContact}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <Stack>
                  <Button onClick={() => routeChange('/register')} fullWidth variant="contained" autoFocus sx={{ my: 2 }}>
                    {t('homesearch.viewListing.SignUpDialog.signUp')}
                  </Button>
                  <Typography variant="subtitle1" textAlign="center" sx={{ marginBottom: 1 }}>
                    {t('homesearch.viewListing.SignUpDialog.haveAnAccount')}&nbsp;
                    <Link onClick={() => routeChange('/login')} color="primary">
                      {t('homesearch.viewListing.SignUpDialog.signIn')}
                    </Link>
                    .
                  </Typography>
                </Stack>
                <hr />
                <Typography variant='caption'>
                  {t('homesearch.viewListing.hereToHelp')}
                </Typography>
                <br />
                <Typography variant='caption'>
                  {t('homesearch.viewListing.sendUsAnEmail')}&nbsp;
                    <Link href="mailto:support@residentnow.com" color="primary">
                      support@residentnow.com
                    </Link>
                    .
                </Typography>
              </DialogContent>
            </Dialog>
            : 
            <Dialog
              open={openContact}
              onClose={handleCloseContact}
            >
              <DialogTitle sx={{ paddingBottom: 0 }}>
                {t('homesearch.viewListing.buyDialog.title')}
                <hr />
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseContact}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent sx={{ paddingTop: 0 }}>
                <Typography variant="subtitle1">
                  {t('homesearch.viewListing.buyDialog.content1.part1')} <strong>{t('homesearch.viewListing.buyDialog.content1.part2')}</strong> {t('homesearch.viewListing.buyDialog.content1.part3')}
                </Typography>
                <Typography variant="subtitle1" sx={{ paddingBottom: 3 }}>
                  {t('homesearch.viewListing.buyDialog.content2.part1')} <strong>{t('homesearch.viewListing.buyDialog.content2.part2')}</strong>.
                </Typography>
                <Button onClick={() => routeChange('/pricing')} fullWidth variant="contained" autoFocus sx={{ my: 4 }}>
                  {t('homesearch.viewListing.buyDialog.getAccess')}
                </Button>
                <hr />
                <Typography variant='caption'>
                  {t('homesearch.viewListing.hereToHelp')}
                </Typography>
                <br />
                <Typography variant='caption'>
                  {t('homesearch.viewListing.sendUsAnEmail')}&nbsp;
                    <Link href="mailto:support@residentnow.com" color="primary">
                      support@residentnow.com
                    </Link>
                    .
                </Typography>
              </DialogContent>
            </Dialog>
        }
      </Card>
    </Grow>
  );
}

export default function HomeSearch({ activeSearch, setActiveSearch, userData }) {
  const {t} = useTranslation("common");
  
  const navigate = useNavigate();

  const routeChange = (path) =>{ 
    navigate(path);
  }

  const [activeSearchLoaded, setActiveSearchLoaded] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [searchLocationSizeActive, setSearchLocationSizeActive] = React.useState(true);
  const [searchPriceActive, setSearchPriceActive] = React.useState(false);
  const [searchSizeActive, setSearchSizeActive] = React.useState(false);
  const [searchLifestyleInventoryActive, setSearchLifestyleInventoryActive] = React.useState(false);
  const [searchFacilitiesActive, setSearchFacilitiesActive] = React.useState(false);
  const [searchStyleActive, setSearchStyleActive] = React.useState(false);
  const [searchResultActive, setSearchResultActive] = React.useState(false);
  const [viewListingCardActive, setViewListingCardActive] = React.useState(false);

  const [searchCity, setSearchCity] = React.useState("København");
  const [searchMonthlyRent, setSearchMonthlyRent] = React.useState(5000);
  const [searchMonthlyAccount, setSearchMonthlyAccount] = React.useState(1000);
  const [searchDeposit, setSearchDeposit] = React.useState(20000);
  const [searchSize, setSearchSize] = React.useState(50);
  const [searchRooms, setSearchRooms] = React.useState(3);
  const [searchStyle, setSearchStyle] = React.useState(0);
  const [searchLifestyle, setSearchLifestyle] = React.useState({
    searchPets: false,
    searchShareable: false,
    searchSmoking: false,
    searchAgeFriendly: false,
  });
  const [searchInventory, setSearchInventory] = React.useState({
    searchFurnished: false,
    searchWashingMachine: false,
    searchDryerMachine: false,
    searchDishWasher: false,
  });
  const [searchFacilities, setSearchFacilities] = React.useState({
    searchBalcony: false, 
    searchGarden: false,
    searchParking: false,
    searchChargingStation: false,
    searchElevator: false,
    searchStorage: false,
  });
  
  const [searchPriorities, setSearchPriorities] = React.useState({
    searchCity: false,
    searchMonthlyRent: false,
    searchMonthlyAccount: false,
    searchDeposit: false,
    searchSize: false,
    searchRooms: false,
    searchBalcony: false, 
    searchGarden: false,
    searchPets: false,
    searchParking: false,
    searchChargingStation: false,
    searchElevator: false,
    searchStorage: false,
    searchShareable: false,
    searchSmoking: false,
    searchFurnished: false,
    searchAgeFriendly: false,
    searchWashingMachine: false,
    searchDryerMachine: false,
    searchDishWasher: false,
  });

  const [searchResults, setSearchResults] = React.useState([]);
  const [searchInProgress, setSearchInProgress] = React.useState(false);
  const [selectedListing, setSelectedListing] = React.useState({
    'images': [DemoApartmentImage1],
    'rooms': 2,
    'size': 58,
    'monthly_rent': 6500,
    'monthly_water_account': 250,
    'monthly_heating_account': 250,
    'deposit': 19500,
    'prepaid_rent': 6500, 
    'move_in_date': "01/06/2024",
    'rental_period': "Unlimited",
    'listing_type': 'apartment',
    'description': `Velkommen til Godsbanen i det fantastiske centrum af Aalborg!\n
      Lige nu har du mulighed for at leje denne nye 2-værelses lejlighed med en rummelig, vestvendt altan og alle hvidevarer inkluderet.\n
      Køkkenet er udstyret med en praktisk ø, køle/fryseskab, ovn, kogeplade og emhætte, mens badeværelset forkæler dig med gulvvarme og et effektivt ventilationsanlæg samt vaskesøjle.\n
      Hele boligen er designet med praktisk ventilationssystem og er godt isoleret, både for at reducere støj og temperaturudsving.\n
      Soveværelset tilbyder tilstrækkelig plads til en seng samt et praktisk indbygget skab.\n
      Fra den lille entré i lejligheden er der adgang til et teknikrum, som fungerer som boligens depotrum.\n
      Ejendommen tilbyder også en aflåst cykelkælder samt mulighed for at tilkøbe en egen parkeringsplads blot få meters gang fra bygningen.\n
      For sikkerheden er ejendommen altid låst med nøgle og dørkode, og der er installeret dørtelefoner i alle boliger. Der er også tilknyttet viceværtsservice til alle lejligheder på adressen for at sikre en problemfri oplevelse for beboerne.`,
    'currency': 'DDK',
  });

  const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
  const [successAlertText, setSuccessAlertText] = React.useState("Success!");
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const [errorAlertText, setErrorAlertText] = React.useState("Error!");

  const setSuccessAlert = (text) => {
    setSuccessAlertText(text);
    setOpenSuccessAlert(true);
  };

  const handleCloseSuccessAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const setErrorAlert = (text) => {
    setErrorAlertText(text);
    setOpenErrorAlert(true);
  };

  const handleCloseErrorAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorAlert(false);
  };

  const steps = ['homesearch.steps.locationSize', 'homesearch.steps.price', 'homesearch.steps.lifestyleInventory', 'homesearch.steps.facilities'];

  if (activeSearch && !activeSearchLoaded) {
    setActiveSearchLoaded(true);
    setSearchCity(activeSearch.searchCity);
    setSearchMonthlyRent(activeSearch.searchMonthlyRent);
    setSearchMonthlyAccount(activeSearch.searchMonthlyAccount);
    setSearchDeposit(activeSearch.searchDeposit);
    setSearchSize(activeSearch.searchSize);
    setSearchRooms(activeSearch.searchRooms);
    setSearchLifestyle(activeSearch.searchLifestyle);
    setSearchInventory(activeSearch.searchInventory);
    setSearchFacilities(activeSearch.searchFacilities);
    setSearchPriorities(activeSearch.searchPriorities);
  }

  const handleActiveCardOut = (next) => {
    if (activeStep === 0) {
      setSearchLocationSizeActive(false);
    }
    if (activeStep === 1) {
      setSearchPriceActive(false);
    }
    else if (activeStep === 2) {
      setSearchLifestyleInventoryActive(false);
    }
    else if (activeStep === 3) {
      setSearchFacilitiesActive(false);
    }
    else if (activeStep === 4) {
      setSearchResultActive(false);
    }
    if (next) {
      setActiveStep(activeStep + 1);
    }
    else {
      setActiveStep(activeStep - 1);
    }
  }

  const handleActiveCardIn = async () => {
    if (activeStep === 0) {
      setSearchLocationSizeActive(true);
    }
    if (activeStep === 1) {
      setSearchPriceActive(true);
    }
    else if (activeStep === 2) {
      setSearchLifestyleInventoryActive(true);
    }
    else if (activeStep === 3) {
      setSearchFacilitiesActive(true);
    }
    else if (activeStep === 4) {
      setSearchInProgress(true);
      await getSearchResults();
      setSearchResultActive(true);
      setSearchInProgress(false);
    }
  }

  const changeSelectedListing = (data) => {
    if (viewListingCardActive) {
      setViewListingCardActive(false);
      setSearchResultActive(true);
      setActiveStep(4);
    }
    else {
      setSearchResultActive(false);
      setViewListingCardActive(true);
      setSelectedListing(data);
      setActiveStep(5);
    }
  }

  const getSearchResults = useCallback(async () => {
    await fetch(`/get-search-results?search_data=${
      JSON.stringify({
        "searchCity": searchCity,  
        "searchMonthlyRent": searchMonthlyRent, 
        "searchMonthlyAccount": searchMonthlyAccount, 
        "searchDeposit": searchDeposit, 
        "searchSize": searchSize, 
        "searchRooms": searchRooms, 
        ...searchLifestyle,
        ...searchInventory,
        ...searchFacilities,
        "searchPriorities": searchPriorities,
      })}`).then((res) =>
      res.json().then((data) => {
        setSearchResults(data);
        setActiveSearch({
          "searchCity": searchCity, 
          "searchMonthlyRent": searchMonthlyRent, 
          "searchMonthlyAccount": searchMonthlyAccount, 
          "searchDeposit": searchDeposit, 
          "searchSize": searchSize, 
          "searchRooms": searchRooms,
          "searchLifestyle": searchLifestyle,
          "searchInventory": searchInventory,
          "searchFacilities": searchFacilities,
          "searchPriorities": searchPriorities,
        });
      })
    );
  }, [searchCity, searchMonthlyRent, searchMonthlyAccount, searchDeposit, searchSize, searchRooms, searchLifestyle, searchInventory, searchFacilities, searchPriorities, setSearchResults, setActiveSearch]);

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        backgroundImage: "url(" + BackgroundImage1 + ")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      }} 
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ minWidth: { sm: 360, md: 860 }, marginTop: 16, display: { xs: 'None', sm: 'flex' } }}>
          { activeStep < 4 && steps.map((label) => (
            <Step key={label} sx={{
                '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.200', // Text label
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: 'common.white', // Text label (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.700', // Text label (COMPLETED)
                },
              }}
            >
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <SearchLocationSizeCard active={searchLocationSizeActive} onExited={handleActiveCardIn} onNext={() => handleActiveCardOut(true)} onPrev={() => routeChange('/')} searchPriorities={searchPriorities} setSearchPriorities={setSearchPriorities} searchCity={searchCity} setSearchCity={setSearchCity} searchSize={searchSize} setSearchSize={setSearchSize} searchRooms={searchRooms} setSearchRooms={setSearchRooms} />
        <SearchPriceCard active={searchPriceActive} onExited={handleActiveCardIn} onNext={() => handleActiveCardOut(true)} onPrev={() => handleActiveCardOut()} searchPriorities={searchPriorities} setSearchPriorities={setSearchPriorities} searchMonthlyRent={searchMonthlyRent} setSearchMonthlyRent={setSearchMonthlyRent} searchMonthlyAccount={searchMonthlyAccount} setSearchMonthlyAccount={setSearchMonthlyAccount} searchDeposit={searchDeposit} setSearchDeposit={setSearchDeposit} />
        <SearchLifestyleInventoryCard active={searchLifestyleInventoryActive} onExited={handleActiveCardIn} onNext={() => handleActiveCardOut(true)} onPrev={() => handleActiveCardOut()} searchPriorities={searchPriorities} setSearchPriorities={setSearchPriorities} searchLifestyle={searchLifestyle} setSearchLifestyle={setSearchLifestyle} searchInventory={searchInventory} setSearchInventory={setSearchInventory} />
        <SearchSizeCard active={searchSizeActive} onExited={handleActiveCardIn} onNext={() => handleActiveCardOut(true)} onPrev={() => handleActiveCardOut()} searchPriorities={searchPriorities} setSearchPriorities={setSearchPriorities} searchSize={searchSize} setSearchSize={setSearchSize} searchRooms={searchRooms} setSearchRooms={setSearchRooms} />
        <SearchFacilitiesCard active={searchFacilitiesActive} onExited={handleActiveCardIn} onNext={() => handleActiveCardOut(true)} onPrev={() => handleActiveCardOut()} searchPriorities={searchPriorities} setSearchPriorities={setSearchPriorities} searchFacilities={searchFacilities} setSearchFacilities={setSearchFacilities} />
        {searchInProgress ? 
          <LinearProgress sx={{ 
              width: '70%',
              position: 'absolute',
              top: '50%',
              left: '15%', 
            }} 
          />
          :
          <SearchResultCard active={searchResultActive} onExited={handleActiveCardIn} onPrev={() => handleActiveCardOut()} results={searchResults} changeSelectedListing={changeSelectedListing} setSuccessAlert={setSuccessAlert} setErrorAlert={setErrorAlert} />
        }
        <ViewListingCard active={viewListingCardActive} routeChange={routeChange} data={selectedListing} onPrev={changeSelectedListing} userData={userData} />
        <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleCloseSuccessAlert}>
          <Alert
            onClose={handleCloseSuccessAlert}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {successAlertText}
          </Alert>
        </Snackbar>
        <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleCloseErrorAlert}>
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorAlertText}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
