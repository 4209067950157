import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography';

export default function ReadMoreText({ text, lessLength }) {
  const [showFullText, setShowFullText] = React.useState(false);

  const showFullTextHandler = () => {
    setShowFullText(!showFullText);
  };

  const readableText = showFullText
    ? text
    : text.slice(0, lessLength);

  return (
    <Typography>
      {readableText}
      <Button onClick={showFullTextHandler}>
        Read {showFullText ? "Less" : "More"}
      </Button>
    </Typography>
  )
}