import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import 'leaflet/dist/leaflet.css';

import common_en from "./static/translations/en-US/en.json";
import common_dk from "./static/translations/da-DK/da.json";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'dk',
    resources: {
        en: {
            common: common_en
        },
        dk: {
            common: common_dk
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-ncn0xru4hfuqbnlq.eu.auth0.com"
      clientId="LFzZU7X71DgZDyr11gN6qP8oumbsUobW"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://dev-ncn0xru4hfuqbnlq.eu.auth0.com/api/v2/",
        scope: "read:current_user update:current_user_metadata"
      }}
      
    >
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
