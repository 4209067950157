import * as React from 'react';
import DOMPurify from "dompurify";
import Box from '@mui/material/Box';

import TOS from './static/TOS';

export default function TOSPage() {

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
      }} 
    >
      <Box
        sx={{
					minWidth: { sm: 500, md: 800, lg: 1100 },
					maxWidth: { sm: 500, md: 800, lg: 1100 },
					my: 10,
					mx: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
				<Box sx={{
					mt: { xs: 4, sm: 8 },
					mb: { xs: 4, sm: 8 },
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center', 
				}}>
					<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(TOS)}} />
				</Box>
      </Box>
    </Box>
  );
}