export const validateStrLength = (string, minLength=null, maxLength=null) => {
    if (typeof(string) === 'undefined' || string === null) return true
    if ((minLength !== null && string.length < minLength) || (maxLength !== null && string.length > maxLength)) return true
    return false
  }

export const validateNumberRange = (number, minValue=null, maxValue=null) => {
if (typeof(number) === 'string') {
    number = parseFloat(number);
}
if (isNaN(number) || typeof(number) === 'undefined' || number === null) return true
if ((minValue !== null && number < minValue) || (maxValue !== null && number > maxValue)) return true
return false
}