import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import TOSDialog from './TOSDialog'

import BackgroundImage from '../static/copenhagen-skyline-in-the-evening-denmark.jpg';

export default function Hero() {
  const {t} = useTranslation("common");

  const navigate = useNavigate();
  
  const routeChange = (path) =>{ 
    navigate(path);
  }

  const [openTOS, setOpenTOS] = React.useState(false);

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        height: '100%',
        color: 'white',
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 4, sm: 6 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ height: { xs: 240, sm: 600 }, width: { xs: '100%', sm: '70%' }, flexGrow: 1 }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            {t('hero.title')}
            {/* <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              made easy
            </Typography> */}
          </Typography>
          <Typography
            textAlign="center"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            {t('hero.subtitle.line1')}<br/>{t('hero.subtitle.line2')}
          </Typography>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, display: { xs: '', md: 'none' } }}>
            {t('hero.TOS.label')}&nbsp;
            <Link onClick={() => setOpenTOS(true)} color="primary" sx={{ cursor: 'pointer' }}>
              {t('hero.TOS.link')}
            </Link>
            .
          </Typography>
          <Button variant="contained" color="primary" onClick={() => routeChange("search")}>
            {t('hero.startNowButton.label')}
          </Button>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, display: { xs: 'none', md: 'block' } }}>
            {t('hero.TOS.label')}&nbsp;
            <Link onClick={() => setOpenTOS(true)} color="primary" sx={{ cursor: 'pointer' }}>
              {t('hero.TOS.link')}
            </Link>
            .
          </Typography>
        </Stack>
        <KeyboardDoubleArrowDownIcon sx={{ alignSelf: 'center', fontSize: { xs: 60, md: 80 } }} />
      </Container>
      <TOSDialog open={openTOS} setOpen={setOpenTOS} />
    </Box>
  );
}
