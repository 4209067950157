import * as React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import PaidIcon from '@mui/icons-material/Paid';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import BuildIcon from '@mui/icons-material/Build';

export default function Highlights() {
  const {t} = useTranslation("common");

  const items = [
    {
      icon: <SearchIcon />,
      title: t('highlights.card1.title'),
      description:
        t('highlights.card1.description'),
    },
    {
      icon: <AutoFixHighRoundedIcon />,
      title: t('highlights.card2.title'),
      description:
        t('highlights.card2.description'),
    },
    {
      icon: <ThumbUpAltRoundedIcon />,
      title: t('highlights.card3.title'),
      description:
        t('highlights.card3.description'),
    },
    {
      icon: <PaidIcon />,
      title: t('highlights.card4.title'),
      description:
        t('highlights.card4.description'),
    },
    {
      icon: <HomeWorkIcon />,
      title: t('highlights.card5.title'),
      description:
        t('highlights.card5.description'),
    },
    {
      icon: <BuildIcon />,
      title: t('highlights.card6.title'),
      description:
        t('highlights.card6.description'),
    },
  ];

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            {t('highlights.title')}
          </Typography>
          <Typography variant="body1">
            {t('highlights.subtitle')}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.100',
                  background: 'transparent',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
