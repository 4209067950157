import React from "react";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function LanguageSelector({ language, setLanguage }) {
    return (
      <FormControl variant="standard">
        <Select
          id="language-selector-select"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"dk"}>Dansk</MenuItem>
        </Select>
      </FormControl>
    )
}