import * as React from 'react';
import { useTranslation } from "react-i18next";
import styled from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BallotIcon from '@mui/icons-material/Ballot';
import AddHomeIcon from '@mui/icons-material/AddHome';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, iconOnlyListItems } from './components/dashboard/listItems';
import { grey } from '@mui/material/colors';

import ActiveListings from './components/dashboard/ActiveListings';
import CreateListing from './components/dashboard/CreateListing';
import OrganisationSettings from './components/dashboard/OrganisationSettings';

import Chart from './components/dashboard/Chart';
import Deposits from './components/dashboard/Deposits';
import Orders from './components/dashboard/Orders';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Resident Now ApS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function StatsPage() {
  return (
    <Grid container spacing={3} xs={12}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <Chart />
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <Deposits />
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Orders />
        </Paper>
      </Grid>
    </Grid>
  )
}

function ActivePage({ page, userData }) {
  const activeListings = <ActiveListings userData={userData} />;
  const createListing = <CreateListing />;
  const organisationSettings = <OrganisationSettings userData={userData} />;
  if (page === 'activeListings') {
    return activeListings;
  } else if (page === 'createListing') {
    return createListing;
  } else if (page === 'settings') {
    return organisationSettings;
  }
  return <h1>Loading...</h1>
}

export default function Dashboard({ userData }) {
  const {t} = useTranslation("common");

  const [activePage, setActivePage] = React.useState('activeListings');

  return (
    <Box sx={{ display: 'flex', backgroundColor: grey[100] }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container sx={{ mt: 4, mb: 4 }}>
          <Paper>
            <Grid container sx={{
                minHeight: {xs: 0, md: 590},
                justifyContent: {xs: 'center', sm: 'start'},
              }}
            >
              <Grid
                container
                sm={1}
                md={3}
              >
                <Box
                  sx={{
                    display: { xs: 'None', md: 'flex' },
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <List component="nav" sx={{
                      display: { xs: 'None', md: 'block' },
                      backgroundColor: 'white',
                      mt: 1,
                      px: 2,
                    }}
                  >
                    {/* <ListItemButton onClick={() => setActivePage('stats')}>
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItemButton> */}
                    <Typography variant="body2" color="text.secondary">
                      {t("dashboard.navMenu.listings.title")}
                    </Typography>
                    <hr/>
                    <ListItemButton onClick={() => setActivePage('activeListings')}>
                      <ListItemIcon>
                        <BallotIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('dashboard.navMenu.activeListings')} />
                    </ListItemButton>
                    <ListItemButton onClick={() => setActivePage('createListing')}>
                      <ListItemIcon>
                        <AddHomeIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('dashboard.navMenu.createListing')} />
                    </ListItemButton>
                    <Typography variant="body2" color="text.secondary">
                      {t("dashboard.navMenu.organisation.title")}
                    </Typography>
                    <hr/>
                    <ListItemButton onClick={() => setActivePage('settings')}>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('dashboard.navMenu.settings')} />
                    </ListItemButton>
                  </List>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'None', md: 'block' } }}/>
                <Paper
                  sx={{
                    mb: { xs: 2, sm: 0 },
                    display: { xs: 'flex', md: 'None' },
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                >
                  <List component="nav" sx={{
                      display: { xs: 'flex', sm: 'block', md: 'None' },
                      backgroundColor: 'white',
                    }}
                  >
                    {/* <ListItemButton onClick={() => setActivePage('stats')}>
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <DashboardIcon />
                      </ListItemIcon>
                    </ListItemButton> */}
                    <ListItemButton onClick={() => setActivePage('activeListings')} sx={{
                        maxHeight: 64,
                        maxWidth: 64,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <BallotIcon />
                      </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setActivePage('createListing')} sx={{
                        maxHeight: 64,
                        maxWidth: 64,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <AddHomeIcon />
                      </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton onClick={() => setActivePage('settings')} sx={{
                        maxHeight: 64,
                        maxWidth: 64,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <SettingsIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  </List>
                </Paper>
              </Grid>
              <Grid
                container
                xs={11}
                md={9}
              >
                <ActivePage
                  page={activePage}
                  userData={userData}
                  sx={{
                    flexGrow: 1,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}
