import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';

export default function ContactSignUpDialog({ openContact, handleCloseContact }) {
  const {t} = useTranslation("common");

  const navigate = useNavigate();

  const routeChange = (path) =>{ 
    navigate(path);
  }

  return (
    <Dialog
      open={openContact}
      onClose={handleCloseContact}
    >
      <DialogTitle sx={{ maxWidth: 300 }}>
        {t('contactSignUpDialog.title')}
        <hr />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseContact}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack>
          <Button onClick={() => routeChange('/register')} fullWidth variant="contained" autoFocus sx={{ my: 2 }}>
            {t('contactSignUpDialog.signUp')}
          </Button>
          <Typography variant="subtitle1" textAlign="center" sx={{ marginBottom: 1 }}>
            {t('contactSignUpDialog.haveAnAccount')}&nbsp;
            <Link onClick={() => routeChange('/login')} color="primary">
                {t('contactSignUpDialog.signIn')}
            </Link>
            .
          </Typography>
        </Stack>
        <hr />
        <Typography variant='caption'>
          {t('contactSignUpDialog.hereToHelp')}
        </Typography>
        <br />
        <Typography variant='caption'>
          {t('contactSignUpDialog.sendUsAnEmail')}&nbsp;
          <Link href="mailto:support@residentnow.com" color="primary">
              support@residentnow.com
          </Link>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
