import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useAuth from "./helpers/authentication.js";

import BackgroundImage from "./static/aerial-view-of-copenhagen-in-the-evening-denmark.jpg"

export default function CreateOrganisation() {
  const {t} = useTranslation("common");
  
  const { getAccessTokenSilently } = useAuth();
  const navigate = useNavigate();

  const routeChange = (path) => { 
    navigate(path);
  }

  const [orgName, setOrgName] = React.useState("");
  const [orgEmail, setOrgEmail] = React.useState("");
  const [orgCountryCode, setOrgCountryCode] = React.useState("+45");
  const [orgPhone, setOrgPhone] = React.useState("");
  const [orgAddress, setOrgAddress] = React.useState("");
  const [orgContactEmail, setOrgContactEmail] = React.useState("");
  const [orgContactCountryCode, setOrgContactCountryCode] = React.useState("+45");
  const [orgContactPhone, setOrgContactPhone] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    await fetch('/create-organisation-api', {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
        body: JSON.stringify({
          "name": orgName, 
          "email": orgEmail, 
          "orgCountryCode": orgCountryCode,
          "phone": orgPhone,
          "address": orgAddress,
          "contactEmail": orgContactEmail,
          "contactCountryCode": orgContactCountryCode,
          "contactPhone": orgContactPhone,
        })
      }).then((res) =>
      res.json().then((data) => {
        if (data === 201 || data === 200) {
          routeChange('/dashboard');
          window.location.reload();
        }
      })
    );
  };
  
  const countryCodes = [
    "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", "+40", "+41", "+43", "+44",
    "+45", "+46", "+47", "+48", "+49", "+51", "+52", "+53", "+54", "+55", "+56", "+57", "+58", "+60", "+61",
    "+62", "+63", "+64", "+65", "+66", "+81", "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95",
    "+98", "+211", "+212", "+213", "+216", "+218", "+220", "+221", "+222", "+223", "+224", "+225", "+226",
    "+227", "+228", "+229", "+230", "+231", "+232", "+233", "+234", "+235", "+236", "+237", "+238", "+239",
    "+240", "+241", "+242", "+243", "+244", "+245", "+246", "+247", "+248", "+249", "+250", "+251", "+252",
    "+253", "+254", "+255", "+256", "+257", "+258", "+260", "+261", "+262", "+263", "+264", "+265", "+266",
    "+267", "+268", "+269", "+290", "+291", "+297", "+298", "+299", "+350", "+351", "+352", "+353", "+354",
    "+355", "+356", "+357", "+358", "+359", "+370", "+371", "+372", "+373", "+374", "+375", "+376", "+377",
    "+378", "+379", "+380", "+381", "+382", "+383", "+385", "+386", "+387", "+389", "+420", "+421", "+423",
    "+500", "+501", "+502", "+503", "+504", "+505", "+506", "+507", "+508", "+509", "+590", "+591", "+592",
    "+593", "+594", "+595", "+596", "+597", "+598", "+599", "+670", "+672", "+673", "+674", "+675", "+676",
    "+677", "+678", "+679", "+680", "+681", "+682", "+683", "+685", "+686", "+687", "+688", "+689", "+690",
    "+691", "+692", "+850", "+852", "+853", "+855", "+856", "+880", "+886", "+960", "+961", "+962", "+963",
    "+964", "+965", "+966", "+967", "+968", "+970", "+971", "+972", "+973", "+974", "+975", "+976", "+977",
    "+992", "+993", "+994", "+995", "+996", "+998", "+1242", "+1246", "+1264", "+1268", "+1284", "+1340",
    "+1345", "+1441", "+1473", "+1649", "+1664", "+1670", "+1671", "+1684", "+1721", "+1758", "+1767",
    "+1784", "+1849", "+1868", "+1869", "+1876", "+1939"
  ];

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }} 
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grow direction="up" in={true} mountOnEnter unmountOnExit>
          <Card sx={{ 
              minHeight: 300,
              minWidth: 300,
              maxWidth: 360,
              my: 16,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
            }}
          >
            <CardHeader title={t('createOrganisation.title')} />
            <CardContent>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                sx={{
                  minWidth: { xs: 300, md: 660 },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }} 
              >
                <Stack spacing={2} sx={{
                    mx: 5,
                    marginBottom: 5 
                  }}
                >
                  <FormControl>
                    <TextField type="text" id="org-name-input" label={t('createOrganisation.orgName.label')} value={orgName} onChange={e => setOrgName(e.target.value)} variant="outlined" />
                    {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                  </FormControl>
                  <FormControl>
                    <TextField type="email" id="org-email-input" label={t('createOrganisation.orgEmail.label')} value={orgEmail} onChange={e => setOrgEmail(e.target.value)} variant="outlined" />
                    {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                  </FormControl>
                  <FormControl>
                    <Box>
                      <Select
                        id="org-country-code-select"
                        value={orgCountryCode}
                        onChange={e => setOrgCountryCode(e.target.value)}
                        autoWidth
                      >
                        {countryCodes.map((countryCode) => (
                          <MenuItem value={countryCode}>{countryCode}</MenuItem>
                        ))}
                      </Select>
                      <TextField type="tel" id="org-phone-input" label={t('createOrganisation.orgPhone.label')} value={orgPhone} onChange={e => setOrgPhone(e.target.value)} variant="outlined" />
                      {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                    </Box>
                  </FormControl>
                  <FormControl>
                    <TextField type="text" id="org-address-input" label={t('createOrganisation.orgAddress.label')} placeholder="Sølvgade 50, København, 1307.." value={orgAddress} onChange={e => setOrgAddress(e.target.value)} variant="outlined" />
                    {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                  </FormControl>
                  <hr />
                  <FormControl>
                    <TextField type="tel" id="org-contact-email-input" label={t('createOrganisation.contactEmail.label')} value={orgContactEmail} onChange={e => setOrgContactEmail(e.target.value)} variant="outlined" />
                    {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                  </FormControl>
                  <FormControl>
                    <Box>
                      <Select
                        id="org-contact-country-code-select"
                        value={orgContactCountryCode}
                        onChange={e => setOrgContactCountryCode(e.target.value)}
                        autoWidth
                      >
                        {countryCodes.map((countryCode) => (
                          <MenuItem value={countryCode}>{countryCode}</MenuItem>
                        ))}
                      </Select>
                      <TextField type="tel" id="org-contact-phone-input" label={t('createOrganisation.contactPhone.label')} value={orgContactPhone} onChange={e => setOrgContactPhone(e.target.value)} variant="outlined" />
                      {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
                    </Box>
                  </FormControl>
                  <Button
                    fullWidth
                    type='submit'
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t('createOrganisation.createButton.label')}
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grow>
      </Box>
    </Box>
  );
}