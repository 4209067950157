import * as React from 'react';
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import TOS from '../static/TOS'

export default function TOSDialog({ open, setOpen }) {
  const {t} = useTranslation("common");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
    >
      <DialogTitle>{t('TOSDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText
          tabIndex={-1}
        >
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(TOS)}} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('TOSDialog.cancel.label')}</Button>
        <Button onClick={handleClose}>{t('TOSDialog.accept.label')}</Button>
      </DialogActions>
    </Dialog>
  );
}