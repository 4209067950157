import * as React from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import LanguageSelector from './components/LanguageSelector';
import useAuth from "./helpers/authentication";

import BackgroundImage from "./static/copenhagen-skyline-in-the-evening-denmark.jpg"

export default function AccountSettings({ userData, language, setLanguage }) {
  const {t} = useTranslation("common");

  const { getAccessTokenSilently } = useAuth();

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const [name, setName] = React.useState(userData.name);
  const [email, setEmail] = React.useState(userData.email);
  const [phoneCountryCode, setPhoneCountryCode] = React.useState(userData.phone_country_code);
  const [phoneNumber, setPhoneNumber] = React.useState(userData.phone);
  const [password, setPassword] = React.useState("");
  const [emailList, setEmailList] = React.useState(userData.email_list);
  const [callList, setCallList] = React.useState(userData.call_list);

  const [nameError, setNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const countryCodes = [
    "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", "+40", "+41", "+43", "+44",
    "+45", "+46", "+47", "+48", "+49", "+51", "+52", "+53", "+54", "+55", "+56", "+57", "+58", "+60", "+61",
    "+62", "+63", "+64", "+65", "+66", "+81", "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95",
    "+98", "+211", "+212", "+213", "+216", "+218", "+220", "+221", "+222", "+223", "+224", "+225", "+226",
    "+227", "+228", "+229", "+230", "+231", "+232", "+233", "+234", "+235", "+236", "+237", "+238", "+239",
    "+240", "+241", "+242", "+243", "+244", "+245", "+246", "+247", "+248", "+249", "+250", "+251", "+252",
    "+253", "+254", "+255", "+256", "+257", "+258", "+260", "+261", "+262", "+263", "+264", "+265", "+266",
    "+267", "+268", "+269", "+290", "+291", "+297", "+298", "+299", "+350", "+351", "+352", "+353", "+354",
    "+355", "+356", "+357", "+358", "+359", "+370", "+371", "+372", "+373", "+374", "+375", "+376", "+377",
    "+378", "+379", "+380", "+381", "+382", "+383", "+385", "+386", "+387", "+389", "+420", "+421", "+423",
    "+500", "+501", "+502", "+503", "+504", "+505", "+506", "+507", "+508", "+509", "+590", "+591", "+592",
    "+593", "+594", "+595", "+596", "+597", "+598", "+599", "+670", "+672", "+673", "+674", "+675", "+676",
    "+677", "+678", "+679", "+680", "+681", "+682", "+683", "+685", "+686", "+687", "+688", "+689", "+690",
    "+691", "+692", "+850", "+852", "+853", "+855", "+856", "+880", "+886", "+960", "+961", "+962", "+963",
    "+964", "+965", "+966", "+967", "+968", "+970", "+971", "+972", "+973", "+974", "+975", "+976", "+977",
    "+992", "+993", "+994", "+995", "+996", "+998", "+1242", "+1246", "+1264", "+1268", "+1284", "+1340",
    "+1345", "+1441", "+1473", "+1649", "+1664", "+1670", "+1671", "+1684", "+1721", "+1758", "+1767",
    "+1784", "+1849", "+1868", "+1869", "+1876", "+1939"
  ];

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const userInfoSubmit = async (event) => {
    event.preventDefault();

    await fetch('/update-user-info', {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
        body: JSON.stringify({
          "name": name,
          "email": email,
          "phone_country_code": phoneCountryCode,
          "phone": phoneNumber,
        })
      }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  const userPasswordSubmit = async (event) => {
    event.preventDefault();

    await fetch('/update-user-password', {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
        body: JSON.stringify({
          "password": password,
        })
      }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  const emailListSignUp = async (event) => {
    await fetch('/add-user-to-email-list', {
      headers: {'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
    }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
        setEmailList(true);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  const emailListRemove = async (event) => {
    await fetch('/remove-user-from-email-list', {
      headers: {'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
    }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
        setEmailList(false);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  const callListSignUp = async (event) => {
    await fetch('/add-user-to-call-list', {
      method: "POST",
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
      body: JSON.stringify({
        "phone_country_code": phoneCountryCode,
        "phone": phoneNumber,
      })
    }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
        setCallList(true);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  const callListRemove = async (event) => {
    await fetch('/remove-user-from-call-list', {
      method: "GET",
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${await getAccessTokenSilently()}`}, 
    }).then((res) =>
      res.json().then(() => {
        setOpenSuccess(true);
        setCallList(false);
      }).catch(() => {
        setOpenError(true);
      })
    );
  };

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }} 
    >
      <Box
        sx={{
          mx: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grow direction="up" in={true} mountOnEnter unmountOnExit>
          <Card sx={{ 
              minWidth: { xs: '100%', lg: 1135 },
              maxWidth: { xs: '100%', lg: 1135 },
              my: 16,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title={t('accountSettings.title')} />
            <CardContent>
              <Typography variant="h6">
                {t('accountSettings.userInfo.title')}
              </Typography>
              <hr />
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={userInfoSubmit}
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                }} 
              >
                <Stack spacing={2} sx={{
                    mx: 5,
                    marginBottom: 5 
                  }}
                >
                  <FormControl>
                    <TextField 
                      type="text" 
                      id="name-input" 
                      label={t('accountSettings.name.label')} 
                      value={name} 
                      error={nameError}
                      onChange={e => setName(e.target.value)} 
                      onBlur={() => setNameError()}
                      variant="outlined" 
                    />
                  </FormControl>
                  <FormControl>
                    <TextField 
                      type="email" 
                      id="email-input" 
                      label={t('accountSettings.email.label')} 
                      value={email} 
                      error={emailError}
                      onChange={e => setEmail(e.target.value)} 
                      onBlur={() => setEmailError()}
                      variant="outlined" 
                    />
                  </FormControl>
                  <FormControl>
                    <Box>
                      <Select
                        id="phone-country-code-select"
                        value={phoneCountryCode}
                        onChange={e => setPhoneCountryCode(e.target.value)}
                        autoWidth
                      >
                        {countryCodes.map((countryCode) => (
                          <MenuItem value={countryCode}>{countryCode}</MenuItem>
                        ))}
                      </Select>
                      <TextField
                        type="tel"
                        id="phone-number"
                        label={t('accountSettings.phoneNumber.label')}
                        value={phoneNumber}
                        error={phoneNumberError}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        onBlur={() => setPhoneNumberError()}
                        autoComplete="phone-number"
                        variant="outlined" 
                        sx={{
                          maxWidth: 182
                        }}
                      />
                    </Box>
                  </FormControl>
                  <Button type="submit" variant="outlined">
                    {t('accountSettings.updateUserInfo.button.label')}
                  </Button>
                </Stack>
              </Box>
              <Typography variant="h6">
                {t('accountSettings.userPassword.title')}
              </Typography>
              <hr />
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={userPasswordSubmit}
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                }} 
              >
                <Stack spacing={2} sx={{
                    mx: 5,
                    marginBottom: 5 
                  }}
                >
                  <FormControl>
                    <TextField 
                      type="password" 
                      id="password-input" 
                      label={t('accountSettings.password.label')} 
                      value={password} 
                      error={passwordError}
                      onChange={e => setPassword(e.target.value)} 
                      onBlur={() => setPasswordError()}
                      variant="outlined" 
                    />
                  </FormControl>
                  <Button type="submit" variant="outlined">
                    {t('accountSettings.updateUserPassword.button.label')}
                  </Button>
                </Stack>
              </Box>
              <Typography variant="h6">
                {t('accountSettings.billing.title')}
              </Typography>
              <hr />
              <Stack spacing={2} sx={{
                  mx: 5,
                  marginBottom: 5 
                }}
              >
                <form  
                  id="manage-billing-form" 
                  action="/create-customer-portal-session"
                  method="POST"
                >
                  <Button type="submit" variant="outlined">
                    {t('accountSettings.manageBilling.button.label')}
                  </Button>
                  <input name="customer_id" id="customer_id" type="hidden" value={userData.stripe_customer_id} />
                </form>
              </Stack>
              <Typography variant="h6">
                {t('accountSettings.userListSubscriptions.title')}
              </Typography>
              <hr />
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                }} 
              >
                <Stack spacing={2} sx={{
                    mx: 5,
                    marginBottom: 5 
                  }}
                >
                  {(emailList ? 
                    <Button variant="outlined" onClick={emailListRemove}>
                      {t('accountSettings.removeEmailList.button.label')}
                    </Button> :
                    <Button variant="outlined" onClick={emailListSignUp}>
                      {t('accountSettings.addEmailList.button.label')}
                    </Button>
                  )}
                  {(callList ? 
                    <Button variant="outlined" onClick={callListRemove}>
                      {t('accountSettings.removeCallList.button.label')}
                    </Button> :
                    <Button variant="outlined" onClick={callListSignUp}>
                      {t('accountSettings.addCallList.button.label')}
                    </Button>
                  )}
                </Stack>
              </Box>
              <Typography variant="h6">
                {t('accountSettings.language.title')}
              </Typography>
              <hr />
              <Box
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                }} 
              >
                <Stack spacing={2} sx={{
                    mx: 5,
                    marginBottom: 5 
                  }}
                >
                  <LanguageSelector language={language} setLanguage={setLanguage} />
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grow>
      </Box>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {t('accountSettings.success.alert')}
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {t('accountSettings.error.alert')}
        </Alert>
      </Snackbar>
    </Box>
  );
}