import * as React from 'react';
import Box from '@mui/material/Box';

import Pricing from './components/Pricing';

import BackgroundImage from "./static/aerial-view-of-copenhagen-in-the-evening-denmark.jpg";

export default function PricingPage({ userData }) {

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
      }} 
    >
      <Box
        sx={{
					minWidth: { sm: 500, md: 800, lg: 1100 },
					maxWidth: { sm: 500, md: 800, lg: 1100 },
					my: 10,
					mx: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
				<Box sx={{
					mt: { xs: 4, sm: 8 },
					mb: { xs: 4, sm: 8 },
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center', 
				}}>
					<Pricing userData={userData} headerColor={'white'} />
				</Box>
      </Box>
    </Box>
  );
}