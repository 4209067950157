import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import useAuth from "../helpers/authentication.js";

export default function DashboardNavButton({ userData }) {
  const {t} = useTranslation("common");

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const routeChange = (path) =>{ 
    navigate(path);
  }

  return (
    (isAuthenticated && userData) && (
      userData.organisation ? (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => routeChange('/dashboard')}
        >
          {t('dashboardNavButton.dashboard')}
        </Button>
      ): (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => routeChange('/create-organisation')}
        >
          {t('dashboardNavButton.signUp')}
        </Button>
      )
    )
  )
}