import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import useAuth from "./helpers/authentication.js";

import BackgroundImage from "./static/aerial-view-of-copenhagen-in-the-evening-denmark.jpg"

export default function RegistrationPage() {
  const {t} = useTranslation("common");
  
  const { login, register } = useAuth();

  const navigate = useNavigate();

  const routeChange = (path) => { 
    navigate(path);
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [registrationError, setRegistrationError] = React.useState("");
  const [openError, setOpenError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const handleRegistration = async (event) => {
    event.preventDefault();
    let {succeeded, error} = await register(name, email, password);
    if (succeeded) {
      await login(email, password);
      routeChange('/');
      window.location.reload();
    } else {
      setOpenError(true);
      if (error.includes("name")) {
        setNameError(true);
        setEmailError(false);
        setPasswordError(false);
      } 
      if (error.includes("email")) {
        setNameError(false);
        setEmailError(true);
        setPasswordError(false);
      } 
      if (error.includes("password")) {
        setNameError(false);
        setEmailError(false);
        setPasswordError(true);  
      }
      setRegistrationError(error);
    }
  };

  return (
    <Box
      sx={{
        minWidth: '100%',
        minHeight: '100%',
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grow direction="up" in={true} mountOnEnter unmountOnExit>
          <Card sx={{
              minHeight: 300,
              minWidth: 270,
              maxWidth: 310,
              my: { xs: 16, md: 22},
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CardHeader title={t('registrationPage.title')} />
            <CardContent>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleRegistration}
                sx={{
                  minWidth: { xs: 270, md: 270 },
                  maxWidth: { xs: 270, md: 310 },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }} 
              >
                <Stack spacing={3} sx={{
                    mx: 5,
                    marginBottom: 5 
                  }}
                >
                  <Collapse in={openError}>
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenError(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      {registrationError}
                    </Alert>
                  </Collapse>
                  <FormControl>
                    <TextField 
                      type="text" 
                      id="name-input" 
                      label={t('registrationPage.name.label')} 
                      value={name} 
                      error={nameError}
                      onChange={e => setName(e.target.value)} 
                      onBlur={() => setNameError()}
                      variant="outlined" 
                    />
                  </FormControl>
                  <FormControl>
                    <TextField 
                      type="email" 
                      id="email-input" 
                      label={t('registrationPage.email.label')} 
                      value={email} 
                      error={emailError}
                      onChange={e => setEmail(e.target.value)} 
                      onBlur={() => setEmailError()}
                      variant="outlined" 
                    />
                  </FormControl>
                  <FormControl>
                    <TextField 
                      type="password" 
                      id="password-input" 
                      label={t('registrationPage.password.label')} 
                      value={password} 
                      error={passwordError}
                      onChange={e => setPassword(e.target.value)} 
                      onBlur={() => setPasswordError()}
                      variant="outlined" 
                    />
                  </FormControl>
                  <Button
                    fullWidth
                    type='submit'
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t('registrationPage.registerButton.label')}
                  </Button>
                  <FormControl>
                    <Typography variant='subtitle1' sx={{
                        minWidth: { xs: 270, md: 270 },
                        maxWidth: { xs: 270, md: 310 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }} 
                    >
                      {t('registrationPage.loginText.label')}
                    </Typography>
                    <Button variant="text" onClick={() => routeChange('/login')}>{t('registrationPage.loginButton.label')}</Button>
                  </FormControl>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grow>
      </Box>
    </Box>
  );
}