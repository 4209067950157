import * as React from 'react';
import { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { red, green } from '@mui/material/colors';

import useAuth from "../../helpers/authentication.js";

export default function ActiveListings({ userData }) {
  const {t} = useTranslation("common");

  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  
  const [activeListings, setActiveListings] = useState(false);
  const [activeListingsSplit, setActiveListingsSplit] = useState(false);

  const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
  const [successAlertText, setSuccessAlertText] = React.useState("Success!");
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const [errorAlertText, setErrorAlertText] = React.useState("Error!");

  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCloseSuccessAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessAlert(false);
  };

  const setSuccessAlert = (text) => {
    setSuccessAlertText(text);
    setOpenSuccessAlert(true);
  };

  const handleCloseErrorAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorAlert(false);
  };

  const setErrorAlert = (text) => {
    setErrorAlertText(text);
    setOpenErrorAlert(true);
  };

  const getOrganisationListings = async () => {
    if (userData) {
      await fetch(`/get-organisation-listings?org_id=${
          JSON.stringify(userData.organisation)}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) =>
        res.json().then((data) => {
          setActiveListings(data);
        })
      );
    }
  };

  const deleteListing = async (listingId) => {
    await fetch(`/delete-listing?listing_id=${
      JSON.stringify(listingId)}`, {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    }).then((res) =>
      res.json().then(() => {
        setSuccessAlert(t('dashboard.activeListings.deleteListing.successAlert'));
        getOrganisationListings();
      }).catch(() => {
        setErrorAlert(t('dashboard.activeListings.deleteListing.errorAlert'));
      })
    );
  };

  const setListingSold = async (listingId) => {
    await fetch(`/set-listing-sold?listing_id=${
      JSON.stringify(listingId)}`, {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    }).then((res) =>
      res.json().then(() => {
        setSuccessAlert(t('dashboard.activeListings.setListingSold.successAlert'));
        getOrganisationListings();
      }).catch(() => {
        setErrorAlert(t('dashboard.activeListings.setListingSold.errorAlert'));
      })
    );
  };
  
  if (isAuthenticated && !activeListings) {
    getOrganisationListings();
  }

  if (activeListings && !activeListingsSplit) {
    let listingsSplit = [];
    let currentList = [];
    activeListings.forEach((listing, index) => {
      currentList.push(listing);
      if (currentList.length === 10 || index === activeListings.length - 1) {
        listingsSplit.push(currentList);
        currentList = [];
      }
      console.log(currentList);
      console.log(listingsSplit);
    });
    setActiveListingsSplit(listingsSplit);
  }

  return (
    isAuthenticated && (
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }} 
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Grow direction="up" in={true} mountOnEnter unmountOnExit>
            <Box sx={{ 
                width: '100%',
                height: '100%',
                // minWidth: { xs: 300, md: 660 },
                // maxWidth: { xs: 360, md: 860 },
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexGrow: 1, 
              }}
            >
              <Typography variant='h5' sx={{ p: 2 }}>{t('dashboard.activeListings.title')}</Typography>
              <Box>
                <List>
                  {activeListingsSplit ? activeListingsSplit[page-1].map((listing) => (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" onClick={() => deleteListing(listing._id)} aria-label="delete">
                          <DeleteIcon sx={{ color: red[700] }} />
                        </IconButton>
                      }
                    >
                      <ListItemButton role={undefined} dense>
                        <ListItemText primary={`${listing.type === "apartment" ? t('dashboard.activeListings.listingType.apartment') : t('dashboard.activeListings.listingType.house')} - ${listing.address} - ${listing.city}`} />
                      </ListItemButton>
                      <IconButton edge="end" onClick={() => setListingSold(listing._id)} aria-label="sold">
                        <CheckCircleOutlineIcon sx={{ color: green[500] }} />
                      </IconButton>
                    </ListItem>
                  )): 
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>}
                </List>
              </Box>
              {activeListingsSplit && <Pagination count={Math.ceil(activeListings.length / 10)} page={page} onChange={handleChange} sx={{ mt: 1, mb: 4 }} />}
            </Box>
          </Grow>
        </Box>
        <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleCloseSuccessAlert}>
          <Alert
            onClose={handleCloseSuccessAlert}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {successAlertText}
          </Alert>
        </Snackbar>
        <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleCloseErrorAlert}>
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorAlertText}
          </Alert>
        </Snackbar>
      </Box>
    )
  );
}